<template>
  <header ref="banner" role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container--fluid fr-px-2w">
        <div class="fr-header__body-row">
          <div class="fr-header__brand">
            <div class="fr-header__brand-top">
              <div class="fr-header__logo" target="_blank">
                <a href="https://www.info.gouv.fr/" class="fr-logo" title="Lien vers le site gouvernement.fr">
                  Gouvernement
                </a>
              </div>
              <div class="fr-header__navbar" id="modal-491" aria-labelledby="button-492">
                <button class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-491" type="button"
                  aria-haspopup="menu" aria-expanded="false" id="button-492" title="Menu">
                  Menu
                </button>
              </div>
            </div>
            <div class="fr-header__service">
              <div class="fr-px-2w">
                <router-link id="fr-header-link-accueil" :to="{ name: 'accueil' }"
                  title=" L’Observatoire des données des sites Internet de l’État - Accueil">
                  <p class="fr-header__service-title">
                    Observatoire des sites Internet de l’État
                  </p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="fr-header__tools">
            <div class="fr-header__tools-links">
              <admin-connect-button-dsfr viewport="desktop" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
      <div class="fr-container">
        <button class="fr-btn--close fr-btn" aria-controls="modal-491" title="Fermer">
          Fermer
        </button>
        <div class="fr-header__menu-links">
          <admin-connect-button-dsfr viewport="mobile" />
        </div>
        <app-navigation></app-navigation>
      </div>
    </div>
  </header>
</template>

<script>
import navigationDsfr from "./Navigation.vue";
import { mapGetters } from "vuex";
import AdminConnectButtonDsfr from "./AdminConnectButtonDsfr.vue";

export default {
  name: "headerDsfr",
  components: {
    "admin-connect-button-dsfr": AdminConnectButtonDsfr,
    "app-navigation": navigationDsfr,
  },
};
</script>
