<template>
    <ul class="fr-btns-group" v-if="$keycloak.ready">
        <li>
            <button :id="`user-login-btn-${viewport}`"
                class="fr-btn--account fr-btn fr-icon-account-circle-fill" type="button"
                v-if="!$keycloak.authenticated" @click="$keycloak.loginFn" title="Me connecter">
                Me connecter
            </button>
        </li>
        <li>
            <button v-if="$keycloak.authenticated && $keycloak.hasRealmRole('superAdmin')" :id="`user-space-btn-${viewport}`"
                class="fr-btn--account fr-btn" type="button">
                <router-link :to="{ name: 'admin-home' }" target="_self" title="Mon espace">
                    Mon espace
                </router-link>
            </button>
        </li>
        <li>
            <button :id="`user-logout-btn-${viewport}`"
                class="fr-btn--account fr-btn fr-icon-logout-box-r-line" type="button"
                v-if="$keycloak.authenticated" @click="$keycloak.logoutFn" title="Se déconnecter">
                Se déconnecter
            </button>
        </li>
    </ul>
</template>

<script>
export default {
    name: "AdminConnectButtonDsfr",
    props: {
      viewport: {
        type: String,
        default: "desktop",
      },
    },
};
</script>
