import Vue from "vue";
import Router from "vue-router";
import dsfrAnalytics from "./services/dsfr_analytics";
import store from "./store/store";

Vue.use(Router);

const audienceCommunicationWebsiteLink = "Observatoire des sites Internet de l’État";

const router = new Router({
    mode: "history",
    base: process.env.VUE_APP_PREFIX_PATH,
    routes: [
        {
            path: "/sites",
            name: "sites",
            component: () =>
                import("./views/Sites.vue"),
            meta: {
                title: `Panorama - ${audienceCommunicationWebsiteLink}`,
                theme: 'Panorama',
                dsfrAnalytics: true,
            },
        },
        {
            path: "/visites",
            name: "visites",
            component: () =>
                import("./views/Visit.vue"),
            meta: {
                title: `Sites internet de l'État - ${audienceCommunicationWebsiteLink}`,
                theme: "Audience",
                dsfrAnalytics: true,
            },
        },
        {
            path: "/reseaux-sociaux",
            name: "reseaux-sociaux",
            component: () =>
                import("./views/SocialNetwork.vue"),
            meta: {
                title: `Sites internet de l'État - ${audienceCommunicationWebsiteLink}`,
                theme: "Reseaux sociaux",
                dsfrAnalytics: true,
            },
        },
        {
            path: "/",
            name: "accueil",
            component: () =>
                import( "./views/Home.vue"),
            meta: {
                title: `Accueil - ${audienceCommunicationWebsiteLink}`,
                theme: 'Accueil',
                dsfrAnalytics: true
            },
        },
        {
            path: "/mentions-legales",
            name: "mentions-legales",
            component: () =>
                import("./views/LegalMentions.vue"),
            meta: {
                theme: 'Mentions legales',
                title: `Mentions légales - ${audienceCommunicationWebsiteLink}`,
                dsfrAnalytics: true
            },
        },
        {
            path: "/donnees-personnelles",
            name: "private-data",
            component: () =>
                import("./views/PrivateDataPage.vue"),
            meta: {
                title: `Données personnelles et cookies - ${audienceCommunicationWebsiteLink}`,
                theme: 'Donnees Personnelles',
                dsfrAnalytics: true
            },
        },
        {
            path: "/accessibilite",
            name: "accessibilite",
            component: () =>
                import("./views/AccessibilityPage.vue"),
            meta: {
                title: `Accessibilité - ${audienceCommunicationWebsiteLink}`,
                theme: 'Accessibilite',
                dsfrAnalytics: true
            },
        },
        {
            path: "/admin-home",
            name: "admin-home",
            component: () =>
                import( "./views/AdminHome.vue"),
            meta: {
                requiresAuth: true,
                title: `Admin Home - ${audienceCommunicationWebsiteLink}`,
                theme: 'Admin Home',
                dsfrAnalytics: true
            },
        },
        {
            path: "/admin-main",
            name: "admin-main",
            component: () =>
                import( "./views/AdminMain.vue"),
            meta: {
                requiresAuth: true,
                title: `Admin Main - ${audienceCommunicationWebsiteLink}`,
                theme: 'Admin Main',
                dsfrAnalytics: true
            },
        },
        {
            path: "/acces-refuse",
            name: "forbidden",
            component: () =>
                import( "./views/Forbidden.vue"),
            meta: {
                title: `Accès refusé - ${audienceCommunicationWebsiteLink}`,
                theme: 'Accès refusé',
                dsfrAnalytics: true
            },
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from, next) => {
    const keycloak = router.app.$keycloak
    if (to.meta.requiresAuth) {
        if (!keycloak.authenticated) {
            return keycloak.login();
        }
        return keycloak.hasRealmRole("superAdmin") ? next() : next({ name: "forbidden" });
    }
    next();
});


router.beforeEach(async (to, from, next) => {
    if (to.meta.dsfrAnalytics) {
        await sleep(900);
        dsfrAnalytics({
            path: to.path,
            name: to.name,
            title: to.meta.title,
            theme: to.meta.theme,
        });
    }
    if (to.name === 'accueil' || to.name === 'sites') {
        await store.dispatch('setIsSidenavVisible', false);
        await store.dispatch('setIsSocialNetworkSidenavVisible', false);
    } 
    await store.dispatch('setIsAudience', to.name === 'visites');
    await store.dispatch('setIsSocialNetwork', to.name === 'reseaux-sociaux');
    next();
});

export default router;
